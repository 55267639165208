<template>
  <div v-loading.fullscreen.lock="isLoading" :element-loading-text="$t('common.i_loading')" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div id="leaderboard-layout">
      <div class="l-page-header">
        <el-page-header :title="$t('common.i_back')" :content="$t('index.i_leaderboard')" @back="$router.push('/index')" style="float: left;"></el-page-header>
        <div style="float: right;background-color: black;padding: 2px 6px 3px 6px;border-radius: 20px;">
          <img src="@/assets/images/index/Gold.png" style="width: 14px;vertical-align: middle;" />
          <span style="padding-right: 2px;font-size: 14px;text-decoration: none;vertical-align: middle;"> {{$store.state.goldNum}}</span>
        </div>
      </div>

      <div>
        <div>
          <el-button @click="actLeaderboard=1;toGoldLeaderboard()" :class="actLeaderboard==1?'actShow':''" size="medium" style="width: 30%;background-color: rgba(94, 125, 200, 1);border-radius: 10px 10px 0 0;border: none;color: white;">{{$t('leaderboard.i_totalCoins')}}</el-button>
          <el-button @click="actLeaderboard=2;toFriendLeaderboard()" :class="actLeaderboard==2?'actShow':''" size="medium" style="width: 30%;background-color: rgba(94, 125, 200, 1);border-radius: 10px 10px 0 0;border: none;color: white;">{{$t('leaderboard.i_friends')}}</el-button>
        </div>
        <div v-if="actLeaderboard==1" style="padding-bottom: 20px;">
          <div style="position: relative;margin-bottom: -10px;">
            <!-- 前三名排行榜背景图 -->
            <img src="@/assets/images/leaderboard0011.png" style="width: 90%;" />
            <!-- 皇冠 -->
            <span v-if="goldLeaderboard.leaderboardInfo.length >= 1" class="crown">
              <img src="@/assets/images/leaderboard002.png" style="width: 12%;" />
            </span>
            <!-- 第一名默认头像 -->
            <span class="first-avatar" style="z-index: 1;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 1" src="@/assets/images/leaderboard004.png" style="width: 18%;border-radius: 10px;" />
            </span>
            <!-- 第一名头像 -->
            <span class="first-avatar" style="z-index: 2;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 1" :src="goldLeaderboard.leaderboardInfo[0].icon" style="width: 18%;border-radius: 10px;" />
            </span>
            <!-- 第一名名字和总金币 -->
            <span v-if="goldLeaderboard.leaderboardInfo.length >= 1" class="first-name-gold">
              <!-- 名字 -->
              <div v-if="goldLeaderboard.leaderboardInfo[0].userName.length > 14">
                <marquee scrolldelay="300" style="width: 90px;color: white;">{{goldLeaderboard.leaderboardInfo[0].userName}}</marquee>
              </div>
              <span v-else>
                <span style="display: inline-block;margin-bottom: 4px;color: white;">{{goldLeaderboard.leaderboardInfo[0].userName}}</span>
                <br />
              </span>
              <!-- 总金币 -->
              <span style="color: white;vertical-align: middle;">{{goldLeaderboard.leaderboardInfo[0].number}} </span>
              <img src="@/assets/common/goldIcon.png" style="width: 18px;vertical-align: middle;" />
            </span>
            <!-- 第二名默认头像 -->
            <span class="second-avatar" style="z-index: 1;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 2" src="@/assets/images/leaderboard004.png" style="width: 9%;border-radius: 10px;" />
            </span>
            <!-- 第二名头像 -->
            <span class="second-avatar" style="z-index: 2;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 2" :src="goldLeaderboard.leaderboardInfo[1].icon" style="width: 9%;border-radius: 10px;" />
            </span>
            <!-- 第二名名字和总金币 -->
            <span v-if="goldLeaderboard.leaderboardInfo.length >= 2" class="second-name-gold">
              <!-- 名字 -->
              <div v-if="goldLeaderboard.leaderboardInfo[1].userName.length > 8">
                <marquee scrolldelay="300" style="width: 60px;color: white;">{{goldLeaderboard.leaderboardInfo[1].userName}}</marquee>
              </div>
              <span v-else>
                <span style="display: inline-block;margin-bottom: 4px;color: white;">{{goldLeaderboard.leaderboardInfo[1].userName}}</span>
                <br />
              </span>
              <!-- 总金币 -->
              <span style="color: white;vertical-align: middle;">{{goldLeaderboard.leaderboardInfo[1].number}} </span>
              <img src="@/assets/common/doublePerson.png" style="width: 18px;vertical-align: middle;" alt="" />
            </span>
            <!-- 第三名默认头像 -->
            <span class="third-avatar" style="z-index: 1;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 3" src="@/assets/images/leaderboard004.png" style="width: 28%;border-radius: 10px;" />
            </span>
            <!-- 第三名头像 -->
            <span class="third-avatar" style="z-index: 2;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 3" :src="goldLeaderboard.leaderboardInfo[2].icon" style="width: 28%;border-radius: 10px;" />
            </span>
            <!-- 第三名名字和总金币 -->
            <span v-if="goldLeaderboard.leaderboardInfo.length >= 3" class="third-name-gold">
              <!-- 名字 -->
              <div v-if="goldLeaderboard.leaderboardInfo[2].userName.length > 8">
                <marquee scrolldelay="300" style="width: 60px;color: white;">{{goldLeaderboard.leaderboardInfo[2].userName}}</marquee>
              </div>
              <span v-else>
                <span style="display: inline-block;margin-bottom: 4px;color: white;">{{goldLeaderboard.leaderboardInfo[2].userName}}</span>
                <br />
              </span>
              <!-- 总金币 -->
              <span style="vertical-align: middle;color: white;">{{goldLeaderboard.leaderboardInfo[2].number}} </span>
              <img src="@/assets/common/doublePerson.png" style="width: 18px;vertical-align: middle;" />
            </span>
          </div>
          <div style="width: 86%;background-color: rgba(36, 36, 36, 1);margin: auto;padding-bottom: 2%;">
            <div style="padding-top: 2%;"></div>
            <!-- 我的排行榜 -->
            <div v-if="goldLeaderboard.myRanking != null" style="background-color: rgba(36, 36, 36, 1);color:white;overflow: auto;margin-top: 2%;padding: 2% 4%;">
              <div :style="{'text-align': 'left','float': 'left','width': nameWith+'%','vertical-align': 'middle'}">
                <span style="width: 16%;display: inline-block;text-align: center;vertical-align: middle;">{{goldLeaderboard.myRanking.positionDesc}}</span>
                <span style="position: relative;margin-left: 3%;margin-right: 3%;">
                  <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                  <img :src="goldLeaderboard.myRanking.icon" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                </span>
                <span style="vertical-align: middle;width: 61%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;">{{goldLeaderboard.myRanking.userName}}</span>
              </div>
              <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': goldWidth+'%'}">
                <span style="vertical-align: middle;">{{goldLeaderboard.myRanking.number}} </span>
                <img src="@/assets/common/goldIcon.png" style="width: 20px;vertical-align: middle;" alt="" />
              </span>
            </div>
            <!-- 排行榜 -->
            <div v-for="(leaderboard, index) in goldLeaderboard.leaderboardInfo" :key="index">
              <div v-if="index+1>3" style="margin-top: 2%;overflow: hidden;padding: 2% 4%;color: white;">
                <div :style="{'text-align': 'left','float': 'left','width': nameWith+'%','vertical-align': 'middle'}">
                  <span style="width: 16%;display: inline-block;text-align: center;vertical-align: middle;">{{index+1}}</span>
                  <span style="position: relative;margin-left: 3%;margin-right: 3%;">
                    <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                    <img :src="leaderboard.icon" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                  </span>
                  <span style="vertical-align: middle;width: 61%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;">{{leaderboard.userName}}</span>
                </div>
                <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': goldWidth+'%'}">
                  <span style="vertical-align: middle;">{{leaderboard.number}} </span>
                  <img src="@/assets/common/goldIcon.png" style="width: 20px;vertical-align: middle;" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="actLeaderboard==2" style="padding-bottom: 20px;">
          <div style="position: relative;margin-bottom: -10px;">
            <!-- 前三名排行榜背景图 -->
            <img src="@/assets/images/leaderboard0011.png" style="width: 90%;" />
            <!-- 皇冠 -->
            <span v-if="goldLeaderboard.leaderboardInfo.length >= 1" class="crown">
              <img src="@/assets/images/leaderboard002.png" style="width: 12%;" />
            </span>
            <!-- 第一名默认头像 -->
            <span class="first-avatar" style="z-index: 1;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 1" src="@/assets/images/leaderboard004.png" style="width: 18%;border-radius: 10px;" />
            </span>
            <!-- 第一名头像 -->
            <span class="first-avatar" style="z-index: 2;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 1" :src="goldLeaderboard.leaderboardInfo[0].icon" style="width: 18%;border-radius: 10px;" />
            </span>
            <!-- 第一名名字和总金币 -->
            <span v-if="goldLeaderboard.leaderboardInfo.length >= 1" class="first-name-gold">
              <!-- 名字 -->
              <div v-if="goldLeaderboard.leaderboardInfo[0].userName.length > 14">
                <marquee scrolldelay="300" style="width: 90px;color: white;">{{goldLeaderboard.leaderboardInfo[0].userName}}</marquee>
              </div>
              <span v-else>
                <span style="color: white;display: inline-block;margin-bottom: 4px;">{{goldLeaderboard.leaderboardInfo[0].userName}}</span>
                <br />
              </span>
              <!-- 总金币 -->
              <span style="vertical-align: middle;color: white;">{{goldLeaderboard.leaderboardInfo[0].number}} </span>
              <img src="@/assets/images/leaderboard003.png" style="width: 18px;vertical-align: middle;" alt="" />
            </span>
            <!-- 第二名默认头像 -->
            <span class="second-avatar" style="z-index: 1;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 2" src="@/assets/images/leaderboard004.png" style="width: 9%;border-radius: 10px;" />
            </span>
            <!-- 第二名头像 -->
            <span class="second-avatar" style="z-index: 2;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 2" :src="goldLeaderboard.leaderboardInfo[1].icon" style="width: 9%;border-radius: 10px;" />
            </span>
            <!-- 第二名名字和总金币 -->
            <span v-if="goldLeaderboard.leaderboardInfo.length >= 2" class="second-name-gold">
              <!-- 名字 -->
              <div v-if="goldLeaderboard.leaderboardInfo[1].userName.length > 8">
                <marquee scrolldelay="300" style="width: 60px;color: white;">{{goldLeaderboard.leaderboardInfo[1].userName}}</marquee>
              </div>
              <span v-else>
                <span style="color: white;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;margin-bottom: 4px;width: 60px;">{{goldLeaderboard.leaderboardInfo[1].userName}}</span>
                <br />
              </span>
              <!-- 总金币 -->
              <span style="vertical-align: middle;color: white;">{{goldLeaderboard.leaderboardInfo[1].number}} </span>
              <img src="@/assets/images/leaderboard003.png" style="width: 18px;vertical-align: middle;" alt="" />
            </span>
            <!-- 第三名默认头像 -->
            <span class="third-avatar" style="z-index: 1;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 3" src="@/assets/images/leaderboard004.png" style="width: 28%;border-radius: 10px;" />
            </span>
            <!-- 第三名头像 -->
            <span class="third-avatar" style="z-index: 2;">
              <img v-if="goldLeaderboard.leaderboardInfo.length >= 3" :src="goldLeaderboard.leaderboardInfo[2].icon" style="width: 28%;border-radius: 10px;" />
            </span>
            <!-- 第三名名字和总金币 -->
            <span v-if="goldLeaderboard.leaderboardInfo.length >= 3" class="third-name-gold">
              <!-- 名字 -->
              <div v-if="goldLeaderboard.leaderboardInfo[2].userName.length > 8">
                <marquee scrolldelay="300" style="width: 60px;color: white;">{{goldLeaderboard.leaderboardInfo[2].userName}}</marquee>
              </div>
              <span v-else>
                <span style="color: white;display: inline-block;margin-bottom: 4px;">{{goldLeaderboard.leaderboardInfo[2].userName}}</span>
                <br />
              </span>
              <!-- 总金币 -->
              <span style="vertical-align: middle;color: white;">{{goldLeaderboard.leaderboardInfo[2].number}} </span>
              <img src="@/assets/images/leaderboard003.png" style="width: 18px;vertical-align: middle;" alt="" />
            </span>
          </div>
          <div style="width: 86%;background-color: rgba(36, 36, 36, 1);margin: auto;padding-bottom: 2%;">
            <div style="padding-top: 2%;"></div>
            <!-- 我的排行榜 -->
            <div v-if="goldLeaderboard.myRanking != null" style="background-color: rgba(36, 36, 36, 1);margin-top: 2%;overflow: auto;padding: 2% 4%;">
              <div :style="{'text-align': 'left','float': 'left','width': nameWith+'%','vertical-align': 'middle'}">
                <span style="vertical-align: middle;width: 16%;display: inline-block;text-align: center;">{{goldLeaderboard.myRanking.positionDesc}}</span>
                <span style="position: relative;margin-left: 3%;margin-right: 3%;">
                  <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                  <img :src="goldLeaderboard.myRanking.icon" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                </span>
                <span style="vertical-align: middle;width: 61%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;">{{goldLeaderboard.myRanking.userName}}</span>
              </div>
              <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': goldWidth+'%'}">
                <span style="vertical-align: middle;">{{goldLeaderboard.myRanking.number}} </span>
                <img src="@/assets/images/leaderboard003.png" style="width: 20px;vertical-align: middle;" alt="" />
              </span>
            </div>
            <!-- 排行榜 -->
            <div v-for="(leaderboard, index) in goldLeaderboard.leaderboardInfo" :key="index">
              <div v-if="index+1>3" style="margin-top: 2%;overflow: auto;padding: 2% 4%;">
                <div :style="{'text-align': 'left','float': 'left','width': nameWith+'%','vertical-align': 'middle'}">
                  <span style="vertical-align: middle;width: 16%;display: inline-block;text-align: center;">{{index+1}}</span>
                  <span style="position: relative;margin-left: 3%;margin-right: 3%;">
                    <img src="@/assets/images/leaderboard005.png" style="width: 32px;vertical-align: middle;border-radius: 30px;" />
                    <img :src="leaderboard.icon" style="position: absolute;box-sizing: border-box;width: 32px;vertical-align: middle;border-radius: 30px;left: 0;" />
                  </span>
                  <span style="vertical-align: middle;width: 61%;white-space: nowrap;overflow: hidden;display: inline-block;text-overflow: ellipsis;">{{leaderboard.userName}}</span>
                </div>
                <span :style="{'text-align': 'right','float': 'right','vertical-align': 'middle','margin-top': '6px','width': goldWidth+'%'}">
                  <span style="vertical-align: middle;">{{leaderboard.number}} </span>
                  <img src="@/assets/images/leaderboard003V.png" style="width: 20px;vertical-align: middle;" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 返回顶部 -->
      <el-backtop :bottom="10" :right="0" :visibility-height="100">
        <div style="height: 100%;width: 100%;box-shadow: 0 0 6px rgba(0,0,0, .12);text-align: center;line-height: 20px;color: #1989fa;">
          <svg t="1660880120995" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14866" width="32" height="32">
            <path d="M86 919.2c6.8 7.7 17.6 10.5 27.3 7l396.1-143.6 398.8 143.1c9.7 3.5 20.5 0.7 27.3-7.1 6.8-7.8 8.1-18.9 3.3-28L531.1 109.3c-4.3-8.3-12.9-13.4-22.2-13.4-9.3 0-17.9 5.2-22.2 13.5L82.6 891.3c-4.8 9.1-3.4 20.2 3.4 27.9z" p-id="14867" fill="#ffffff"></path>
          </svg>
        </div>
      </el-backtop>
    </div>

    <el-dialog :title="$t('convert.i_tips')" :visible.sync="checkDialog" width="70%" center :closeOnClickModal="false" :closeOnPressEscape="false" :showClose="false">
      <div style="text-align: center;margin-bottom: 10%;">
        <div style="font-size: 16px;color: #000;">{{ leaderboard.failMessage }}</div>
      </div>
      <div style="text-align: center;">
        <el-button round type="primary" @click="go()" style="width: 60%;">
          {{ $t('convert.i_ok') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        isLoading: false,
        checkDialog: false,
        actLeaderboard: 1,
        goldLeaderboard: {
          myRanking: {},
          leaderboardInfo: []
        },
        nameWith: 0,
        goldWidth: 0,
        leaderboard: {
          check: "",
          failMessage: "",
          appScheme: ""
        }
      };
    },
    mounted() {
      document
        .querySelector("html")
        .setAttribute("style", "background-color: rgba(63, 25, 180, 1)");
    },
    beforeDestroy() {
      document.querySelector("html").removeAttribute("style");
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      go() {
        if (this.leaderboard.check == "login") {
          window.location.href = this.leaderboard.appScheme + "login";
        } else {
          window.location.href = this.leaderboard.appScheme;
        }
      },
      toGoldLeaderboard() {
        this.goldLeaderboard.leaderboardInfo = {};
        this.isLoading = true;
        this.$axios({
          url: "/webGift/goldLeaderboard",
          method: "get"
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.goldLeaderboard = result.data.data;
            this.$store.state.goldNum = result.data.data.goldNum;
            if (this.goldLeaderboard.leaderboardInfo.length > 3) {
              var text = this.goldLeaderboard.leaderboardInfo[3].number + "";
              this.goldWidth = 14 + text.length * 3;
              this.nameWith = 100 - this.goldWidth;
            } else {
              var text = this.goldLeaderboard.myRanking.number + "";
              this.goldWidth = 14 + text.length * 3;
              this.nameWith = 100 - this.goldWidth;
            }
          } else if (result.data.code == -2) {
            this.leaderboard.check = result.data.data.check;
            this.leaderboard.failMessage = result.data.message;
            this.leaderboard.appScheme = result.data.data.appScheme;
            this.checkDialog = true;
          }
        });
      },
      toFriendLeaderboard() {
        this.goldLeaderboard.leaderboardInfo = {};
        this.isLoading = true;
        this.$axios({
          url: "/webGift/friendLeaderboard",
          method: "get"
        }).then((result) => {
          this.isLoading = false;
          if (result.data.code == 1) {
            this.goldLeaderboard = result.data.data;
            this.$store.state.goldNum = result.data.data.goldNum;
            if (this.goldLeaderboard.leaderboardInfo.length > 3) {
              var text = this.goldLeaderboard.leaderboardInfo[3].number + "";
              this.goldWidth = 14 + text.length * 3;
              this.nameWith = 100 - this.goldWidth;
            } else {
              var text = this.goldLeaderboard.myRanking.number + "";
              this.goldWidth = 14 + text.length * 3;
              this.nameWith = 100 - this.goldWidth;
            }
          } else {
            this.$message({
              showClose: true,
              message: result.data.message,
              type: "error",
              center: true
            });
          }
        });
      }
    },
    created() {
      this.toGoldLeaderboard();
    }
  };
</script>

<style>
  #leaderboard-layout {
    width: 100%;
    height: 100%;
    background-color: rgba(2, 2, 0, 1);
  }
  #leaderboard-layout .l-page-header {
    overflow: hidden;
    padding: 4%;
  }
  #leaderboard-layout .el-page-header,
  #leaderboard-layout .el-page-header__content {
    color: white !important;
  }
  #leaderboard-layout .taskAct {
    background-color: rgba(62, 72, 103, 1) !important;
    color: white !important;
  }
  #leaderboard-layout .actShow {
    background-color: white !important;
    color: rgba(94, 125, 200, 1) !important;
  }
  #leaderboard-layout .el-backtop {
    background-color: transparent !important;
  }
  /* 皇冠 */
  #leaderboard-layout .crown {
    position: absolute;
    box-sizing: border-box;
    top: 3.5%;
    left: 0;
    right: 0;
    font-size: 12px;
    color: white;
  }
  /* 第一名头像 */
  #leaderboard-layout .first-avatar {
    position: absolute;
    box-sizing: border-box;
    top: 19.5%;
    left: 0;
    right: 0;
    font-size: 12px;
    color: white;
  }
  /* 第一名名字和总金币 */
  #leaderboard-layout .first-name-gold {
    position: absolute;
    box-sizing: border-box;
    top: 51%;
    left: 0;
    right: 0;
    font-size: 12px;
    color: white;
  }
  /* 第二名头像 */
  #leaderboard-layout .second-avatar {
    position: absolute;
    box-sizing: border-box;
    top: 39%;
    left: -53%;
    right: 0;
    font-size: 12px;
    color: white;
  }
  /* 第二名名字和总金币 */
  #leaderboard-layout .second-name-gold {
    position: absolute;
    box-sizing: border-box;
    top: 63%;
    left: -55%;
    right: 0;
    font-size: 12px;
    color: white;
  }
  /* 第三名头像 */
  #leaderboard-layout .third-avatar {
    position: absolute;
    box-sizing: border-box;
    top: 39%;
    left: 52%;
    right: 0;
    font-size: 12px;
    color: white;
  }
  /* 第三名名字和总金币 */
  #leaderboard-layout .third-name-gold {
    position: absolute;
    box-sizing: border-box;
    top: 63%;
    left: 56%;
    right: 0;
    font-size: 12px;
    color: white;
  }
</style>
